.App {
  width: 100vw;
  height: 100vh;
}
.topbar {
  padding: 12px clamp(0px, 5vw, 70px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.toplogo {
  cursor: pointer;
  display: flex;
  background: rgba(217, 217, 217, 0.40);
  border-radius: 100px;
  padding: 10px 12px;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
}
.toplogo > img {
  width: 20px;
  height: 20px;
  padding-left: 2px;
  resize: both;
  filter: invert(1.0);
}
.toplogo > div {
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  color: black;

}
.actionmenu {
  display: flex;
  padding: 10px 0px;
  gap: 15px;
  width: fit-content;
  align-items: center;
}
.actionmenu > div {

color: rgba(0, 0, 0, 0.95);
text-align: center;
font-size: 18px;
font-style: normal;
font-weight: 510;
line-height: normal;
padding: 10px;

}

.button {
  cursor: pointer;
}
.button0 {
  cursor: pointer;
  border: 1px solid transparent;
  transition: 0.3s;
}
.button0:hover {
  border: 1px solid gray;
} 
.button:hover {
  background-color: rgba(217, 217, 217, 0.20);
}
.vdivider {
  height: 20px;
  width: 1px;
  background-color: black;
  margin: none;
}
.hdivider {
  height: 1px;
  width: 80%;
  background-color: black;
  margin: none;
}
.hamburger-menu {
  padding: 6px;
  height: 20px;
  height: min-content;
}
.hamburger-menu > img {
  width: 20px;
  height: 20px;
  resize: both;
}
.outer-links {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 15px;
  background-color: white;
  max-width: 50vw !important;
  min-width: 200px !important;
  z-index: -2;
}
.invisible-burger {
  display: none;
}
.outer-header {
  padding: 8px;
  padding-left: 0px;
  font-size: 18px;
  font-weight: bold;

}
.linkx {
  padding: 8px;
  border: 1px solid black;
  border-radius: 8px;
  text-decoration: none;
  color: black;
}
.outer-bar {
  display: flex !important;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}
.base-x {
  max-width: 1048px;
  max-height: 600px;
  display: flex;
  background: rgba(246, 246, 246, 0.50);
  border-radius: 25px;
  padding: clamp(30px, );
  margin: auto;
  justify-content: center;
  align-items: center;
  padding: 100px 50px;

}
.quote {
  max-width: 1048px;
  display: flex;
  border-radius: 25px;
  margin: auto;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  overflow: hidden;
}
.test-x {
  justify-content: top;
  flex-direction: column;
  gap: 10px;
  align-items: self-start;
  max-height: fit-content;
}
.content-pane {
  margin: 20px clamp(0px, 5vw, 70px);
  height: 100%;
}
.desk {
  width: 380px;
  height: 300px;
  display: flex;
  flex-direction: column-reverse;
  align-content: center;
  justify-content: end;
}
.stand-bottom {
  width: 80px;
  height: 5px;
  border-radius: 0px 0px 10px 10px;
  background: #CBCBCB;
  margin: 0px auto;
}
.stander {
  margin: 0px auto;
  background: #E2E2E2;
  width: 60px;
  height: 45px;
}
.frame {
  position: relative;
  margin: 0px auto;
  width: 320px;
  height: 180px;
  border-radius: 5px;
  border: 3px solid #000;
}
.frame-x {
  position: relative;
  margin: 0px auto;
  width: 320px;
  height: 180px;
  border-radius: 5px;
  border: 3px solid #000;
  background-color: black;
}
.frame-overlay {
  width: 320px;
  height: 180px;
  top: 0px;
  left: 0px;
  position: absolute;
  background: radial-gradient(194.19% 76.51% at 82.03% 76.67%, #22e84d75 0%, rgba(11, 160, 223, 0.5) 100%),
  radial-gradient(57.7% 52.3% at 24.37% 22.78%, rgba(232, 34, 34, 0.50) 0%, rgba(181, 11, 223, 0.32) 100%);
  background-blend-mode: overlay;
}

.frame-overlay2 {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: none;
}
.text-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-overlay > div {
  margin: auto;
  color: #000;
text-align: center;
text-shadow: 0px 4px 4px rgba(255, 255, 255, 0.25);
font-size: 32px;
font-style: normal;
font-weight: 510;
line-height: normal;
width: 50%;
height: fit-content;
}
.gray {
  color: white !important;
}
.topic {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 25px;
  max-width: 440px;
}
.topic-text {
  color: #000;
font-size: 48px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.topic-special-text {
  background: linear-gradient(251deg, #E5A4CE 10.76%, #7DC0F8 91.26%);
  background-clip: text;
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
font-size: 48px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.topic-options {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
}
.topic-options > div {
  width: 100%;
  padding: 1px 10px;
  border-radius: 15px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #184BFF;
  color: white;
  max-width: 230px;
}
.topic-options > div:last-child {
  background: #D9D9D9;
  color: black;
}
.test-header {
  color: #000;
font-size: 30px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.underline {
  text-decoration: underline;
}
.test-description {
  color: #000;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.layer-y {
  display: flex;
  flex-wrap: wrap;
}
.test-layer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 10px;
}
.test-layer > input, .test-layer > select {
  background-color: #E2E2E2;
  border: 1px solid gray;
  width: 220px;
  height: 28px;
  font-size: 16px;
}
select {
  width: 225px !important;
  height: 30px !important;
}
.test-layer > input:focus, .test-layer > select:focus {
  border: 1px solid black;
}

.submit-test {
  width: 220px;
  padding: 5px 2px;
  border-radius: 0px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #E2E2E2;
  color: black; 
  margin-top: 10px;
  border: 1px solid black;
}
.space {
  margin-top: 20px;
}
.error-notify {
  color: gray;
  font-size: 16px;
  width: 100%;
}
table {
  border-collapse: collapse;
}
.table-x {
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: scroll;
  width: 100%;
  height: fit-content;
}
td {
  border: 1px solid gray;
}
.bold {
  font-weight: bold;
}
td:first-child, th {
  border: 1px solid gray;
  min-width: 50px;
  font-size: 16px;
  font-weight: 600;
  background-color: rgba(217, 217, 217, 0.40);
}
.element-green {
  background-color: rgba(0, 128, 0, 0.482);
}
.element-yellow {
  background-color: rgba(255, 255, 0, 0.482);
}
.element-red {
  background-color: rgba(255, 0, 0, 0.439);
}
.spacing {
  white-space: pre-line;
  word-wrap: break-word;
}
.arrow {
  transition: 1s;
  width: 200px;
  height: 80px;
  background: url("./arrow.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: fill;
  opacity: 0.0;
  margin: 20px;
  pointer-events: none;
}
.fade-in {
  opacity: 1.0 !important;
  pointer-events: all;
}
.fade0 {
  opacity: 0.0;
}
@media (max-width: 924px){
  .arrow {
    rotate: 90deg;
  }
  .layer-y {
    flex-direction: column;
  }
}
.speaker {
  background-repeat: no-repeat;
  background-size: contain;
  height: 220px;
  width: 265px;
}
.speaker-background {
  height: 100%;
  -webkit-mask-image: linear-gradient(0deg,transparent,#000);
  mask-image: linear-gradient(0deg,transparent,#000);
  width: 100%;
  overflow: hidden;
}
.mask0 {
  height: calc(100vh - 106px - 53px);
  display: flex;
  padding-bottom: 53px;
}
.selec {
  background-color: rgba(217, 217, 217, 0.20);
}